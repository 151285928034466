import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Accordion } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { useSelector, useStore } from 'react-redux';
import { setShowSidemenu } from '../../redux/reducer';
const Sidebar = (props) => {
    const store = useStore();
    const user = store.getState().app.user.user;
    const { t } = useTranslation();
    const location = useLocation();
    const [activePath, setActivePath] = useState('/');
    const [accordionActiveKey, setAccordionActiveKey] = useState('null');
    const sizeToggler = useSelector((state) => state.app.showSidemenu);
    useEffect(() => {
        const path = location.pathname;
        setActivePath(path);
        if (!sizeToggler) {
            setAccordionActiveKey(null);
        }
    }, [location.pathname, sizeToggler]);
    const handleAccordionToggle = (eventKey) => {
        if (sizeToggler) {
            setAccordionActiveKey(accordionActiveKey === eventKey ? null : eventKey);
        }
    };

    return (
        <div className={`app-sidemenu-bar ${sizeToggler ? 'open' : 'closed'}`}>
            <li className={`app-sidemenu-bar-item ${activePath === '/' ? 'active' : ''}`}>
                <Link to="/">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            space_dashboard
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('home')}
                        </span>

                    </span>
                </Link>
            </li>
            {/* {
                <li className={`app-sidemenu-bar-item ${activePath.includes('admins') ? 'active' : ''}`}>
                    <Link to="/admins">
                        <span className='link-container'>
                            <span className="material-symbols-outlined">
                                shield_person
                            </span>
                            <span className='link_title'>
                                {sizeToggler && t('admins')}
                            </span>
                        </span>
                    </Link>
                </li>
            } */}
            {/* <li className={`app-sidemenu-bar-item ${activePath.includes('users') ? 'active' : ''}`}>
                <Link to="/users">
                    <span className='link-container'>
                        <span className="material-symbols-outlined">
                            account_circle
                        </span>
                        <span className='link_title'>
                            {sizeToggler && t('users')}
                        </span>
                    </span>
                </Link>
            </li> */}
            {
                user.role === 'qa' &&
                <>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('subjects') ? 'active' : ''}`}>
                        <Link to="/subjects">
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    menu_book
                                </span>
                                <span className='link_title'>
                                    {sizeToggler && t('subjects')}
                                </span>
                            </span>
                        </Link>
                    </li>

                    <li className={`app-sidemenu-bar-item ${activePath.includes('colleges') ? 'active' : ''}`}>
                        <Link to="/colleges">
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    account_balance
                                </span>
                                <span className='link_title'>
                                    {sizeToggler && t('colleges')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('majors') ? 'active' : ''}`}>
                        <Link to="/majors">
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    school
                                </span>
                                <span className='link_title'>
                                    {sizeToggler && t('majors')}
                                </span>
                            </span>
                        </Link>
                    </li>
                    <li className={`app-sidemenu-bar-item ${activePath.includes('assignments') ? 'active' : ''}`}>
                        <Link to="/assignments">
                            <span className='link-container'>
                                <span className="material-symbols-outlined">
                                    assignment
                                </span>
                                <span className='link_title'>
                                    {sizeToggler && t('assignments')}
                                </span>
                            </span>
                        </Link>
                    </li>
                </>
            }

            <div className="mt-3">
                <Button
                    className='icon-btn' severity='secondary'
                    style={{
                        marginLeft: 8,
                        marginRight: 12,
                    }}
                    onClick={() => {
                        const check = !sizeToggler;
                        store.dispatch(setShowSidemenu(check));
                    }}
                >
                    {sizeToggler ? (

                        <span className="material-symbols-outlined">
                            first_page
                        </span>
                    ) : (
                        <span className="material-symbols-outlined">
                            last_page
                        </span>
                    )}
                </Button>
            </div>
        </div>
    );
};

export default Sidebar;

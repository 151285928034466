import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div className="">
            <h3>Welcome to TA-PAL</h3>
            <hr></hr>
            <div className='row'>

            </div>
        </div>
    );
}
export default Home;
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { useStore } from 'react-redux';
import { storeSubjectCategory, getSubjectCategories, updateSubjectCategory } from './../../../apis/services';
import { Modal } from 'react-bootstrap';
import { Checkbox } from "primereact/checkbox";
import { useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Avatar } from 'primereact/avatar';
import { InputSwitch } from "primereact/inputswitch";
import { setShowDeleteDialog } from '../../../redux/reducer';
import DeleteModalContent from '../../../commons/DeleteModalContent';
import { getTimeStamp } from '../../../helpers/helpers';
const SubjectCategories = () => {
    const { t } = useTranslation();
    const store = useStore();
    const [subjectCategories, setSubjectCategories] = useState([]);
    const [showAddSubjectCategoriesModal, setShowAddSubjectCategoriesModal] = useState(false);
    const [showEditSubjectCategoriesModal, setShowEditSubjectCategoriesModal] = useState({ show: false, item: '' });
    useEffect(() => {
        loadSubjectCategories();
    }, []);
    const loadSubjectCategories = async () => {
        try {
            const res = await getSubjectCategories();
            setSubjectCategories(res.data.subjectCategories);
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <div>
            <div className="glass-card p-3">
                <div className="d-flex jcsb">
                    <div className='mt-2 mb-2'>
                        <h4>{t('Subject Categories')}</h4>
                    </div>
                    <div className='p-2'>
                        <Button className='p-btn'
                            onClick={() => {
                                setShowAddSubjectCategoriesModal(true);
                            }}
                        > {t('Add Subject Category')}</Button>
                    </div>
                </div>
                <Modal
                    show={showAddSubjectCategoriesModal}
                >
                    <div className='p-4'>
                        <AddSubjectCategories
                            submit={() => {
                                setShowAddSubjectCategoriesModal(false);
                                loadSubjectCategories();
                            }}
                            cancel={() => {
                                setShowAddSubjectCategoriesModal(false);
                            }}
                        />
                    </div>
                </Modal>
                <Modal
                    show={showEditSubjectCategoriesModal.show}
                >
                    <div className='p-4'>
                        <EditSubjectCategories
                            submit={() => {
                                setShowEditSubjectCategoriesModal({ show: false, item: '' });
                                loadSubjectCategories();
                            }}
                            cancel={() => {
                                setShowEditSubjectCategoriesModal({ show: false, item: '' });
                            }}
                            item={showEditSubjectCategoriesModal.item}
                        />
                    </div>
                </Modal>
                {

                }
                <DeleteModalContent
                    reload={() => {
                        loadSubjectCategories();
                    }}
                />
                <div className="data-table mt-2">
                    <DataTable
                        value={subjectCategories}
                        paginator
                        rows={10}
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        rowsPerPageOptions={[10, 25, 50]}
                        dataKey="id"
                        selectionMode="checkbox"
                        filterDisplay="menu"
                        emptyMessage={t('data_not_available')}
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    >
                       
                        <Column
                            field="name"
                            header={t('Category')}
                            sortable
                        />
                      
                        <Column
                            header={t('status')}
                            sortField='status'
                            sortable
                            body={(row) => {
                                return (
                                    <InputSwitch checked={row.status === 1 ? true : false} />
                                )
                            }}
                        />
                        <Column
                            header={t('actions')}
                            body={(row) => {
                                return (
                                    <div className='d-flex'>
                                        <Button
                                            onClick={(e) => {
                                                setShowEditSubjectCategoriesModal({ show: true, item: row });
                                            }}
                                            className='icon-btn mx-1' severity='primary' id="edit-btn">
                                            <span className="material-symbols-outlined">
                                                edit
                                            </span>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                store.dispatch(setShowDeleteDialog({ show: true, url: '/subject-categories/delete/' + row.id }))
                                            }}
                                            className='icon-btn mx-1' severity='danger' id="edit-btn">
                                            <span className="material-symbols-outlined">
                                                delete
                                            </span>
                                        </Button>
                                    </div>
                                )
                            }}
                        />
                    </DataTable>
                </div>
            </div>
        </div>
    );

}

const AddSubjectCategories = (props) => {
    const { t } = useTranslation();
    const [subjectCategoryTitle, setSubjectCategoryTitle] = useState();
    const [status, setStatus] = useState(true);
    const handleAddSubjectCategories = async () => {
        try {
            let data = {
                subjectCategoryTitle:subjectCategoryTitle,
                status:status,
            }
            await storeSubjectCategory(data);
            props.submit();
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <form action=""
            onSubmit={(e) => {
                e.preventDefault();
                handleAddSubjectCategories();
            }}
        >
            <div className="row">
                <div className="col-12">
                    <div className="d-flex jcsb align-items-center">
                        <h5 className='mt-2 mb-3 opacity'>{t('Add Subject Category')}</h5>
                        <Button raised className='icon-btn' severity='secondary' type='button'
                            onClick={() => {
                                props.cancel();
                            }}
                        >
                            <span className="material-symbols-outlined">
                                cancel
                            </span>
                        </Button>
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <div className="form-group">
                        <label htmlFor="subjectCategoryTitle" className='required mb-1' >{t('Category')}</label>
                        <input type="text" id="subjectCategoryTitle" className='form-control' required
                            value={subjectCategoryTitle}
                            onChange={(e) => { setSubjectCategoryTitle(e.target.value) }}
                        />
                    </div>
                </div>
                
               
                <div className="col-md-12 mt-2">
                    <div className="d-flex">
                        <div className="flex align-items-center">
                            <Checkbox inputId="status" name="status" checked={status} className=''
                                onChange={() => {
                                    setStatus(!status);
                                }}
                            />
                            <label htmlFor="status" className="ml-2 mx-2">{t('enable_for_use')}</label>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <Button type='submit' className='p-btn'>{t('save')}</Button>
                </div>
            </div>
        </form>
    );
}
const EditSubjectCategories = (props) => {
    const { t } = useTranslation();
    const item = props.item;
    const [subjectCategoryTitle, setSubjectCategoryTitle] = useState(item.name);
    const [status, setStatus] = useState(item.status === 1 ? true : false);

    const handleEditSubjectCategories = async () => {
        try {
            let data = {
                id:item.id,
                subjectCategoryTitle:subjectCategoryTitle,
                status:status,
            }
            await updateSubjectCategory(data);
            props.submit();
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <form action=""
            onSubmit={(e) => {
                e.preventDefault();
                handleEditSubjectCategories();
            }}
        >
            <div className="row">
                <div className="col-12">
                    <div className="d-flex jcsb align-items-center">
                        <h5 className='mt-2 mb-3 opacity'>{t('Edit Subject Category')}</h5>
                        <Button raised className='icon-btn' severity='secondary' type='button'
                            onClick={() => {
                                props.cancel();
                            }}
                        >
                            <span className="material-symbols-outlined">
                                cancel
                            </span>
                        </Button>
                    </div>
                </div>
                <div className="col-12 mb-2">
                    <div className="form-group">
                        <label htmlFor="subjectCategoryTitle" className='required mb-1' >{t('Category')}</label>
                        <input type="text" id="subjectCategoryTitle" className='form-control' required
                            value={subjectCategoryTitle}
                            onChange={(e) => { setSubjectCategoryTitle(e.target.value) }}
                        />
                    </div>
                </div>
             
                <div className="col-md-12 mt-2">
                    <div className="d-flex">
                        <div className="flex align-items-center">
                            <Checkbox inputId="status" name="status" checked={status} className=''
                                onChange={() => {
                                    setStatus(!status);
                                }}
                            />
                            <label htmlFor="status" className="ml-2 mx-2">{t('enable_for_use')}</label>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-3">
                    <Button type='submit' className='p-btn'>{t('update')}</Button>
                </div>
            </div>
        </form>
    );
}
export default SubjectCategories;